<template>
    <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Notes
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="addingNote">
          <v-row>
            <v-col cols='12'>
              Adding a new note:
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="noteDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="noteDate"
                    label="Date:"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="noteDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(noteDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete v-model="themeA" color="secondary" :items="getThemes" label="Theme A:"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete v-model="themeB" color="secondary" :items="getThemes" label="Theme B:"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete v-model="winner" color="secondary" :items="[themeA, themeB]" label="Winner:" :disabled="!themeA && !themeB"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-center">
              <v-btn outlined color="red" @click="closeNewNote()">
                <v-icon left>fas fa-times</v-icon>
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn outlined color="secondary" :disabled="!noteDate && !themeA && !themeB && !winner" @click="createNote()">
                <v-icon left>fas fa-check</v-icon>
                Submit Note
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12" class="text-center text-md-right">
              <v-btn outlined @click="addNote()">
                <v-icon left small>fas fa-plus-circle</v-icon>
                Add New Note
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-for="note in getNotes" :key="note.id" no-gutters align="center" class="entries">
            <v-col cols="12" md="2">
              {{note.date}}
            </v-col>
            <v-col cols="12" md="3">
              {{note.themeA}}
            </v-col>
            <v-col cols="12" md="1">
              VS
            </v-col>
            <v-col cols="12" md="3">
              {{note.themeB}}
            </v-col>
            <v-col cols="12" md="3">
              {{note.winner}}
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data(){
      return {
        addingNote: false,
        menu      : false,
        noteDate  : "",
        search    : "",
        themeA    : "",
        themeB    : "",
        winner    : ""
      }
    },
    methods: {
      addNote(){
        this.addingNote = true
      },
      closeNewNote(){
        this.addingNote = false
        this.noteDate   = ""
        this.search     = ""
        this.themeA     = ""
        this.themeB     = ""
        this.Winner     = ""
      },
      createNote(){
        let noteData = {
          date  : this.noteDate,
          themeA: this.themeA,
          themeB: this.themeB,
          winner: this.winner
        }
        this.$store.dispatch('createNote', noteData)
        this.closeNewNote()
      }
    },
    computed: {
      getNotes(){
        return this.$store.getters.getNotes
      },
      getThemes(){
        return this.$store.getters.getThemes.map(theme => theme.name).sort()
      }
    }
}
</script>

<style scoped>
.entries:nth-child(odd){
    background: #d7dae5;
}
</style>
